import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import logo from './assets/images/header-logo.svg'

function App() {

    const Content_REF = useRef<HTMLDivElement>(null);
    const [Date, setDate] = useState('day');                        // 일복리, 월복리, 년복리
    const [Equity, setEquity] = useState(0);                        // 투자 원금
    const [ExtraEquity, setExtraEquity] = useState(0);              // 추가 투자금
    const [InvestmentPeriod, setInvestmentPeriod] = useState(0);    // 투자 기간
    const [InterestRate, setInterestRate] = useState(0);            // 투자 이자율

    const [ViewData, setViewData] = useState({});
    const [UpdateData, setUpdateData] = useState<any>({});
    const [showData, setShowData] = useState(false);

    useEffect(() => {

        // {'TOTAL': 1500000, 'TOTAL_EQUITY': 10000000, 'LIST_DATA':[{equity: 15000, total: 30000, per: '3'}]}

        setViewData({});

        if (Equity > 0 && ExtraEquity >= 0 && InvestmentPeriod > 0 && InterestRate > 0) {
            let _TOTAL = Equity;            // 복리 수익
            let _TOTAL_EQUITY = Equity;     // 투자금
            let _LIST_DATA = [];
            for (let x=0; x<InvestmentPeriod; x++) {
                /*
                 * 복리 수익 : 기존 총금액 + ((기존 총금액 + 신규 투자금) / 100 * 이자율)
                 * 투자금 : 원금 + 투자금
                 *
                 *  */

                // console.log(_TOTAL + ' / ' + _TOTAL_EQUITY);

                console.log((_TOTAL + ExtraEquity)/ 100 * InterestRate);

                const before = _TOTAL;
                _TOTAL = (_TOTAL + ExtraEquity) + ((_TOTAL + ExtraEquity) / 100 * InterestRate);
                _TOTAL_EQUITY += ExtraEquity;

                try{
                    _LIST_DATA.push({
                        equity: _TOTAL_EQUITY, total: _TOTAL, per: ((_TOTAL-_TOTAL_EQUITY) / _TOTAL_EQUITY * 100).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    });
                }catch (e) {
                    alert('0이상의 값을 입력해주세요.');
                }

            }
            setViewData({'TOTAL': _TOTAL, 'TOTAL_EQUITY': _TOTAL_EQUITY, LIST_DATA: _LIST_DATA});
            // setTotalEquity(_TOTAL_EQUITY + ExtraEquity);                  // 토탈 투자금 = 현재 투자금 + 추가 투자금

        }

    }, [Equity, ExtraEquity, InterestRate, InvestmentPeriod]);

    const calculate = () => {
        if(Equity > 0 && ExtraEquity > 0 && InvestmentPeriod > 0 && InterestRate > 0 ){
            // console.log(`투자 원금 : ${Equity}`);
            // console.log(`이자율 : ${InterestRate}`);
            // console.log(`추가 투자금 : ${ExtraEquity}`);
            // console.log(`투자기간 : ${InvestmentPeriod}`);
            console.log(ViewData);
            setUpdateData(ViewData);
            setShowData(true);
            setTimeout(() => {
                Content_REF.current?.scrollIntoView({ behavior: 'smooth' });
                console.log(Content_REF.current);
            },300);
        } else {
            alert('0보다 큰 숫자를 입력해주세요.');
        }

    }

    const focusEvent = (e: any) => {
        if (e.target.classList[0] === 'blur') {
            e.target.classList.remove('blur')
            e.target.classList.add('focus')
        } else {
            e.target.classList.remove('focus')
            e.target.classList.add('blur')
        }
    }

    const changeDate = (e: any) => {
        const target = e.target;

        if (target.classList.length > 1) {
            return;
        }

        for (let x=0;x<target.parentNode.children.length;x++) {
            target.parentNode.children[x].classList.remove('Content-Date-Selected');
        }

        target.classList.add('Content-Date-Selected');

        if (target.textContent === '일 복리') {
            setDate('day');
        } else if (target.textContent === '월 복리') {
            setDate('month');
        } else {
            setDate('year');
        }
    }


    const headerClickHandler = (e:any, i:number) => {
        if(i === 0){
            window.location.href = 'https://s3calculator.mrdevellotools.com';
        } else if (i === 1) {
            window.location.href = 'https://sellercalculator.mrdevellotools.com';
        } else if (i === 2){
            window.location.href = 'https://cicalculator.mrdevellotools.com';
        }

    }

    return (
        <>
            <div className="App">
                <div className="Header">
                    <img src={logo} alt={'logo'} />
                    <div className="box top-box" onClick={(e) => headerClickHandler(e, 0)}>
                        1. S3 비용 계산기
                    </div>
                    <div className="box top-box" onClick={(e) => headerClickHandler(e, 1)}>
                        2. 부가세 계산기
                    </div>
                    <div className="box top-box" onClick={(e) => headerClickHandler(e, 2)}>
                        3. 복리 계산기
                    </div>
                </div>
                <div className="Contents-Container">
                    <div className={'min-box'}>
                        <div className="box" onClick={(e) => headerClickHandler(e, 0)}>
                            1. S3 비용 계산기
                        </div>
                        <div className="box" onClick={(e) => headerClickHandler(e, 1)}>
                            2. 부가세 계산기
                        </div>
                        <div className="box" onClick={(e) => headerClickHandler(e, 2)}>
                            3. 복리 계산기
                        </div>
                    </div>

                    <div className="Contents-Box">
                        <div className="Content-Title">
                            복리 계산기
                        </div>
                        <div className="Content-Input-Section">
                            <div className="Content-Date-Box">
                                <div className="Content-Date Content-Date-Selected" onClick={changeDate}>일 복리</div>
                                <div className="Content-Date" onClick={changeDate}>월 복리</div>
                                <div className="Content-Date" onClick={changeDate}>년 복리</div>
                            </div>
                            <div className="Content-Input-Box">
                                <div className="Content-Input-Title">투자 원금</div>
                                <div className="Content-Input">
                                    <input type="number" className="blur" onFocus={focusEvent} onBlur={focusEvent} onChange={el => {
                                        let num: any = el.target.value;
                                        setEquity(num*1);
                                    }} />
                                    <span>원</span>
                                </div>
                            </div>
                            <div className="Content-Input-Box">
                                <div className="Content-Input-Title">{(Date === 'day') ? '일' : (Date === 'month') ? '월' : '년' }간 목표 수익율</div>
                                <div className="Content-Input">
                                    <input type="number" className="blur" onFocus={focusEvent} onBlur={focusEvent} onChange={el => {
                                        let num: any = el.target.value;
                                        setInterestRate(num*1);
                                    }} />
                                    <span>%</span>
                                </div>
                            </div>
                            <div className="Content-Input-Box">
                                <div className="Content-Input-Title">추가 투자금 {(Date === 'day') ? '(일간)' : (Date === 'month') ? '(월간)' : '(년간)' }</div>
                                <div className="Content-Input">
                                    <input type="number" className="blur" onFocus={focusEvent} onBlur={focusEvent} onChange={el => {
                                        let num: any = el.target.value;
                                        setExtraEquity(num*1);
                                    }} />
                                    <span>원</span>
                                </div>
                            </div>
                            <div className="Content-Input-Box">
                                <div className="Content-Input-Title">투자기간</div>
                                <div className="Content-Input">
                                    <input type="Text" className="blur" onFocus={focusEvent} onBlur={focusEvent} onChange={el => {
                                        let num: any = el.target.value;
                                        setInvestmentPeriod(num*1);
                                    }} />
                                    <span>{(Date === 'day') ? '일' : (Date === 'month') ? '개월' : '년' }</span>
                                </div>
                            </div>
                        </div>
                        <div className="Content-Submit-Box">
                            <div className="Content-Submit-Button" onClick={calculate}>계산하기</div>
                        </div>
                    </div>
                    {showData && (
                        <div className="Contents-Box" ref={Content_REF}>
                            <div className="Result-Des-Content">
                                <div className="Result-Des-Box">
                                    <div className="Result-Des-Title">최종 금액</div>
                                    <div className="Result-Des-Value Value-Special">{UpdateData.TOTAL === undefined ? '0' : UpdateData.TOTAL.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</div>
                                </div>
                                <div className="Result-Des-Box">
                                    <div className="Result-Des-Title">총 투자금</div>
                                    <div className="Result-Des-Value">{UpdateData.TOTAL_EQUITY === undefined ? '0' : UpdateData.TOTAL_EQUITY.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</div>
                                </div>
                            </div>

                            <div className="Result-Table">
                                <div className="Result-Table-Thead">
                                    <div className="Result-Table-Thead-Td1">기간</div>
                                    <div className="Result-Table-Thead-Td2">투자금</div>
                                    <div className="Result-Table-Thead-Td3">최종 금액</div>
                                    <div className="Result-Table-Thead-Td4">수익률</div>
                                </div>
                                <div className="Result-Table-Tbody">
                                    {UpdateData.LIST_DATA.map((data: { equity: number; total: number; per: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, index: number) => (
                                        <div className="Result-Table-Tr" key={index}>
                                            <div className="Result-Table-Td1">{index + 1}</div>
                                            <div className="Result-Table-Td2">{data.equity === undefined ? '0' : data.equity.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}원</div>
                                            <div className="Result-Table-Td3">{data.total === undefined ? '0' : data.total.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}원</div>
                                            <div className="Result-Table-Td4">{data.per}%</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="Footer">Copyright 2022. MR.DEVELLO All rights reserved.</div>
            </div>
            <ins className="adsbygoogle"
                 style={{display: 'block'}}
                 data-ad-client="ca-pub-6163050877922629"
                 data-ad-slot="2465804595"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </>
    );
}

export default App;
